<template>
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="25" cy="25" r="25" fill="url(#paint0_linear)" />
    <g filter="url(#filter0_i)">
      <circle cx="25" cy="25" r="16" fill="url(#paint1_linear)" />
    </g>
    <g filter="url(#filter1_d)">
      <path
        d="M21.7416 27.5125L16.2584 23.9875C15.8274 23.7105 15.2568 23.804 14.9368 24.204L14.1092 25.2385C13.7703 25.6621 13.831 26.2787 14.2459 26.6281L22.2477 33.3665C22.6652 33.7181 23.2875 33.6699 23.6459 33.2584L35.7934 19.3113C36.175 18.8731 36.1041 18.2031 35.6393 17.8545L33.2754 16.0815C32.8433 15.7575 32.2319 15.8351 31.8945 16.2568L23.0632 27.296C22.7432 27.696 22.1726 27.7895 21.7416 27.5125Z"
        fill="url(#paint2_linear)"
      />
    </g>
    <defs>
      <filter
        id="filter0_i"
        x="9"
        y="9"
        width="32"
        height="34"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
      </filter>
      <filter
        id="filter1_d"
        x="10.8896"
        y="12.8815"
        width="28.1493"
        height="23.7201"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="25"
        y1="0"
        x2="25"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFF169" />
        <stop offset="1" stop-color="#FFBC00" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="25"
        y1="9"
        x2="25"
        y2="41"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFCF32" />
        <stop offset="1" stop-color="#FF9100" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="24.75"
        y1="15"
        x2="24.75"
        y2="34.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FEF9CA" />
        <stop offset="1" stop-color="#FEF467" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped></style>
