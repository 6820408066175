<template>
  <van-overlay :show="boo" @click="boo = false">
    <div class="wrapper" @click.stop>
      <div class="tips">
        <span>签到成功！</span>
        <p>恭喜您获得<i>1积分</i></p>
        <div class="btn" @click="boo = false">开心手下</div>

        <svg
          @click="boo = false"
          class="tips_error"
          width="63"
          height="63"
          viewBox="0 0 63 63"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="31.5"
            cy="31.5"
            r="30.5"
            stroke="white"
            stroke-width="2"
          />
          <path
            d="M18 18L44.8701 44.8701"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
          />
          <path
            d="M45 18L18.1299 44.8701"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
          />
        </svg>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { Overlay } from "vant";
export default {
  components: {
    vanOverlay: Overlay,
  },
  props: {
    show: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      boo: false,
    };
  },
  created() {
    this.boo = this.show;
  },
  watch: {
    show() {
      this.boo = this.show;
    },
    boo() {
      this.$emit("update:show", this.boo);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

.tips {
  width: rem(558);
  height: rem(335);
  background: #fff;
  border-radius: rem(16);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  > span {
    color: #222222;
    font-weight: bold;
    font-size: rem(34);
    text-align: center;
    display: block;
    margin-top: rem(40);
  }
  > p {
    text-align: center;
    color: #989898;
    font-size: rem(28);
    margin-top: rem(10);
    > i {
      color: #ff4848;
    }
  }
  .btn {
    margin: rem(40) auto 0;
    background: linear-gradient(180deg, #ff6666 0%, #ff4848 105.88%);
    border-radius: rem(84/2);
    width: rem(480);
    height: rem(84);
    color: #f7f7f7;
    font-size: rem(30);
    line-height: rem(84);
    text-align: center;
  }
}
.tips_error {
  position: absolute;
  width: rem(63);
  height: rem(63);
  bottom: rem(-120);
  left: 0;
  right: 0;
  margin: 0 auto;
}
</style>
