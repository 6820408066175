<template>
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="25" cy="25" r="25" fill="url(#paint0_linear)" />
    <g filter="url(#filter0_i)">
      <circle cx="25" cy="25" r="16" fill="url(#paint1_linear)" />
    </g>
    <g filter="url(#filter1_d)">
      <path
        d="M24.1751 16.2036C24.5723 15.624 25.4277 15.624 25.8249 16.2036L28.3257 19.8526C28.4557 20.0423 28.6472 20.1814 28.8678 20.2464L33.111 21.4973C33.7849 21.6959 34.0492 22.5094 33.6208 23.0663L30.9232 26.5723C30.7829 26.7546 30.7098 26.9796 30.7161 27.2096L30.8377 31.6316C30.8571 32.334 30.1651 32.8367 29.5031 32.6013L25.335 31.1191C25.1183 31.0421 24.8817 31.0421 24.665 31.1191L20.4969 32.6013C19.8349 32.8367 19.1429 32.334 19.1623 31.6316L19.2839 27.2096C19.2902 26.9796 19.2171 26.7546 19.0768 26.5723L16.3792 23.0663C15.9508 22.5094 16.2151 21.6959 16.889 21.4973L21.1322 20.2464C21.3528 20.1814 21.5443 20.0423 21.6743 19.8526L24.1751 16.2036Z"
        fill="url(#paint2_linear)"
      />
    </g>
    <defs>
      <filter
        id="filter0_i"
        x="9"
        y="9"
        width="32"
        height="34"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
      </filter>
      <filter
        id="filter1_d"
        x="13.1709"
        y="12.7689"
        width="23.6576"
        height="22.8911"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.05 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear"
        x1="25"
        y1="0"
        x2="25"
        y2="50"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFF169" />
        <stop offset="1" stop-color="#FFBC00" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="25"
        y1="9"
        x2="25"
        y2="41"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFCF32" />
        <stop offset="1" stop-color="#FF9100" />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="25"
        y1="15"
        x2="25"
        y2="35"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FEF9CA" />
        <stop offset="1" stop-color="#FEF467" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {};
</script>

<style lang="sass" scoped></style>
