<template>
  <div id="points">
    <div class="p_info">
      <img
        class="p_i_tx"
        src="https://img2.baidu.com/it/u=3771030443,424141137&fm=26&fmt=auto&gp=0.jpg"
      />
      <div class="p_sub">
        <span>已签到1天</span>
        <b>明天签到可获得1积分</b>
      </div>
      <div class="p_i_incon" @click="$router.push({ name: 'pointsLog' })">
        <oCoins class="icon"></oCoins>
        <span>466</span>
      </div>
    </div>

    <div class="p_sign">
      <div class="title">
        <h1>每日签到</h1>
        <div @click="logShow = true">
          <svg
            width="29"
            height="29"
            viewBox="0 0 29 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="14.5002"
              cy="14.5"
              r="13.1818"
              stroke="#666666"
              stroke-width="2"
            />
            <path
              d="M14.6322 7.25C15.9372 7.25 16.9785 7.59932 17.7563 8.29796C18.5472 8.98341 18.9426 9.9325 18.9426 11.1452C18.9426 12.1207 18.6988 12.9314 18.211 13.5773C18.0001 13.8145 17.4333 14.3352 16.5106 15.1393C16.1679 15.4161 15.9042 15.7457 15.7197 16.128C15.5219 16.5234 15.4231 16.9518 15.4231 17.4132V17.69H13.1492V17.4132C13.1492 16.6355 13.281 15.9895 13.5447 15.4755C13.7951 14.9482 14.5663 14.1111 15.8581 12.9643L16.0954 12.6875C16.4513 12.2525 16.6292 11.7845 16.6292 11.2836C16.6292 10.6509 16.4447 10.1368 16.0756 9.74136C15.7065 9.37227 15.1792 9.18773 14.4938 9.18773C13.6238 9.18773 13.0042 9.45795 12.6351 9.99841C12.3056 10.4466 12.1408 11.0925 12.1408 11.9361H9.88672C9.88672 10.4598 10.3151 9.31295 11.1719 8.49568C12.0288 7.66523 13.1822 7.25 14.6322 7.25ZM14.2763 18.6589C14.7244 18.6589 15.0935 18.7973 15.3835 19.0741C15.6735 19.3641 15.8185 19.72 15.8185 20.1418C15.8185 20.59 15.6669 20.9525 15.3638 21.2293C15.0738 21.5061 14.7113 21.6445 14.2763 21.6445C13.8676 21.6445 13.5051 21.4995 13.1888 21.2095C12.8988 20.9327 12.7538 20.5768 12.7538 20.1418C12.7538 19.7068 12.8988 19.3509 13.1888 19.0741C13.4919 18.7973 13.8544 18.6589 14.2763 18.6589Z"
              fill="#666666"
            />
          </svg>
          <span>规则</span>
        </div>
      </div>
      <ul class="day">
        <li class="act">
          <span>1天</span>
          <oCoinsed></oCoinsed>
          <b>+1</b>
        </li>
        <li>
          <span>2天</span>
          <oCoins></oCoins>
          <b>+1</b>
        </li>
        <li>
          <span>3天</span>
          <oCoins></oCoins>
          <b>+1</b>
        </li>
        <li>
          <span>4天</span>
          <oCoins></oCoins>
          <b>+1</b>
        </li>
        <li>
          <span>5天</span>
          <oCoins></oCoins>
          <b>+1</b>
        </li>
        <li>
          <span>6天</span>
          <oCoins></oCoins>
          <b>+1</b>
        </li>
        <li>
          <span>7天</span>
          <oCoins></oCoins>
          <b>+1</b>
        </li>
      </ul>
      <div class="sign-btn" @click="getSign">
        签到领取
      </div>
    </div>

    <div class="p_product">
      <ul class="pp_ul">
        <li
          v-for="item in dPointGoodsList"
          :key="item.id"
          @click="
            $router.push({ name: 'pointsDetaiils', query: { id: item.id } })
          "
        >
          <img :src="item.goodsimage_full_url" />
          <div class="h1">
            <h1>
              {{ item.goods_name }}
            </h1>
          </div>
          <div class="data">
            <oCoins class="svg"></oCoins>
            <span
              >{{ item.point
              }}{{ item.price == "0.00" ? "" : item.price + "元" }}</span
            >
            <i>已兑368</i>
          </div>
        </li>
      </ul>
    </div>

    <div class="no-data">——已经到底了——</div>
    <oSigned :show.sync="signedShow"></oSigned>
    <oRule :show.sync="logShow"></oRule>
  </div>
</template>

<script>
import oCoins from "./components/coinsIcon.vue";
import oCoinsed from "./components/coinsIconed.vue";
import oSigned from "./components/signed.vue";
import oRule from "./components/rule.vue";
import { post } from "@get/http";
export default {
  components: {
    oCoins,
    oCoinsed,
    oSigned,
    oRule,
  },
  data() {
    return {
      signedShow: false,
      logShow: false,
      dPointGoodsList: [],
    };
  },
  async created() {
    let { list } = await post("/lw.MemberPointGoods/getList");
    this.dPointGoodsList = list;
  },
  methods: {
    async getSign() {
      await post("/lw.Member/signin");
      this.signedShow = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

#points {
  position: relative;
  background: url("~@image/points/bg.png") no-repeat;
  background-size: 100% rem(434);
  padding-top: rem(116);
}

.p_info {
  display: flex;
  background: none;
  align-items: center;
  box-sizing: border-box;
  padding: 0 rem(30);
  .p_i_tx {
    width: rem(104);
    height: rem(104);
    border-radius: 50%;
  }
  .p_sub {
    margin-left: rem(32);
    flex: 1;
    > span {
      display: block;
      font-weight: bold;
      font-size: rem(34);
      color: #ffffff;
      margin-bottom: rem(4);
    }
    > b {
      color: rgba(255, 255, 255, 0.4);
      font-size: rem(26);
    }
  }
  .p_i_incon {
    width: rem(160);
    height: rem(74);
    border: 1px solid rgba(255, 255, 255, 0.4);
    box-sizing: border-box;
    border-radius: rem(16);
    display: flex;
    justify-content: center;
    align-items: center;
    .icon {
      width: rem(50);
      height: rem(50);
    }
    > span {
      color: #ffffff;
      font-size: rem(30);
      font-weight: bold;
      margin-left: rem(16);
    }
  }
}
.p_sign {
  width: rem(690);
  height: rem(430);
  border-radius: rem(16);
  margin: rem(56) auto 0;
  padding: rem(30) rem(24);
  box-sizing: border-box;
  background: #fff;
  .title {
    display: flex;
    align-items: center;
    margin-bottom: rem(30);
    h1 {
      font-weight: bold;
      font-size: rem(28);
      flex: 1;
      color: #222222;
    }
    div {
      display: flex;
      align-items: center;
      svg {
        width: rem(26);
        height: rem(26);
      }
      span {
        font-size: rem(24);
        color: #666666;
        margin-left: rem(7);
      }
    }
  }
  .day {
    display: flex;

    .act {
      background: linear-gradient(180deg, #ff6666 0%, #ff4848 105.88%);
      border-radius: 8px;
      span {
        color: #ffffff;
      }
      b {
        color: rgba(255, 255, 255, 0.4);
      }
    }
    li {
      background: #eeeeee;
      border-radius: rem(8);
      font-size: rem(24);
      height: rem(142);
      margin-right: rem(10);
      &:last-of-type {
        margin-right: 0;
      }
      b {
        color: #989898;
      }
      span {
        color: #222222;
      }
      svg {
        width: rem(50);
        height: rem(50);
        margin: rem(6) 0;
      }
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
  }
}

.sign-btn {
  width: rem(618);
  height: remrem(98);
  background: linear-gradient(180deg, #ff6666 0%, #ff4848 105.88%);
  border-radius: rem(98/2);
  color: #ffffff;
  text-align: center;
  font-size: rem(32);
  font-weight: bold;
  line-height: rem(98);
  margin-top: rem(40);
}
.sign-btn-act {
  background: linear-gradient(180deg, #ff6666 0%, #ff4848 105.88%);
  opacity: 0.3;
}

.p_product {
  margin-top: rem(30);
  .pp_ul {
    display: flex;
    flex-wrap: wrap;
    width: rem(690);
    margin: 0 auto;
    justify-content: space-between;
    li {
      margin-bottom: rem(16);
      background: #ffffff;
      box-shadow: 0px 0px rem(40) #f5f6fa;
      border-radius: rem(16);
      > img {
        border-radius: rem(16) rem(16) 0px 0px;
        width: rem(337);
        height: rem(337);
      }
      .h1 {
        height: rem(72);
        padding: rem(16);
        overflow: hidden;
        // box-sizing: border-box;
        width: rem(305);
        h1 {
          font-weight: bold;
          font-size: rem(26);
          line-height: rem(36);
          @include ell2;
          color: #222222;
        }
      }
      .data {
        box-sizing: border-box;
        padding: 0 rem(16) rem(20);
        display: flex;
        align-items: center;
        .svg {
          width: rem(28);
          height: rem(28);
        }
        > span {
          color: #ff4848;
          font-size: rem(28);
          flex: 1;
          margin-left: rem(8);
        }
        > i {
          color: #fb7e1c;
          font-size: rem(18);
        }
      }
    }
  }
}

.no-data {
  font-size: rem(22);

  text-align: center;
  color: #c1c1c1;
  padding: rem(40) 0 rem(20);
}
</style>
