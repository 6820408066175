<template>
  <van-overlay :show="boo" @click="boo = false">
    <div class="wrapper" @click.stop>
      <div class="rule">
        <span>积分规则</span>
        <ul class="rule-ul">
          <li>
            <h2>1、签到规则</h2>
            <p>
              同一账号每天可签到1次，每签到一次可以获得1积分，积分直接发放至用户账号。
            </p>
          </li>
          <li>
            <h2>2、消费规则</h2>
            <p>购买产品可获得相应的积分。1元=1积分</p>
          </li>
        </ul>
        <svg-icon icon-class="dialog_error" @click="boo = false"></svg-icon>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { Overlay } from "vant";
export default {
  components: {
    vanOverlay: Overlay,
  },
  props: {
    show: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      boo: false,
    };
  },
  created() {
    this.boo = this.show;
  },
  watch: {
    show() {
      this.boo = this.show;
    },
    boo() {
      this.$emit("update:show", this.boo);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

.rule {
  width: rem(558);
  height: rem(552);
  background: #fff;
  border-radius: rem(16);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  > span {
    color: #222222;
    font-weight: bold;
    font-size: rem(34);
    text-align: center;
    display: block;
    margin-top: rem(40);
  }
  .rule-ul {
    width: rem(478);
    margin: rem(35) auto 0;
    li {
      color: #666666;
      font-size: rem(28);
      line-height: rem(40);
      h2 {
        margin-bottom: rem(11);
      }
      p {
        margin-bottom: rem(34);
      }
    }
  }
}
.tips_error {
  position: absolute;
  width: rem(63);
  height: rem(63);
  bottom: rem(-120);
  left: 0;
  right: 0;
  margin: 0 auto;
}
</style>
